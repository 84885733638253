<template>
    <!-- <ul class="vuejs-countdown">
        <li v-if="days > 0 && showDays">
            <p class="digit">{{ days | twoDigits }}</p>
            <p class="text">{{ days > 1 ? 'days' : 'day' }}</p>
        </li>
        <li v-if="showHours">
            <p class="digit">{{ hours | twoDigits }}</p>
            <p class="text">{{ hours > 1 ? 'hours' : 'hour' }}</p>
        </li>
        <li v-if="showMinutes">
            <p class="digit">{{ minutes | twoDigits }}</p>
            <p class="text">min</p>
        </li>
        <li v-if="showSeconds">
            <p class="digit">{{ seconds | twoDigits }}</p>
            <p class="text">Sec</p>
        </li>
    </ul> -->
    <div>
        <div
            class="d-flex align-items-center justify-content-center gap-1 fw-bold fs-5"
            v-if="!laodingPeriod"
        >
            <div>{{ countdownText }}</div>
            <div
                class="count px-1"
                v-if="showDays && days"
                style="width: max-content"
            >
                {{ days | twoDigits }}
                <span style="font-size:14px; margin-left:0.3rem">days</span>
            </div>
            <span v-if="days">:</span>
            <div class="count" v-if="showHours">{{ hours | twoDigits }}</div>
            :
            <div class="count" v-if="showMinutes">
                {{ minutes | twoDigits }}
            </div>
            :
            <div class="count" v-if="showSeconds">
                {{ seconds | twoDigits }}
            </div>
        </div>
        <div v-else :class="$route.name === 'PromotionPage' ? 'fs-5' : ''">
            calculating....
        </div>
    </div>
</template>

<script>
let interval = null;

export default {
    name: 'CountDown',
    props: {
        end: {
            type: String,
        },
        countdownText: {
            type: String,
            default: () => '',
        },
        stop: {
            type: Boolean,
            default: false,
        },
        showDays: {
            type: Boolean,
            default: false,
        },
        showHours: {
            type: Boolean,
            default: false,
        },
        showMinutes: {
            type: Boolean,
            default: false,
        },
        showSeconds: {
            type: Boolean,
            default: false,
        },
        endFunction: {
            type: Function,
            default: () => {
                console.log('Goodbye and thanks for all the fish.');
            },
        },
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            date: null,
            diff: 0,
            laodingPeriod: false,
        };
    },
    mounted() {
        this.laodingPeriod = true;
        if (!this.end) {
            throw new Error("Missing props 'end'");
        }

        let endDate = this.end;
        let parsed = Date.parse(endDate);

        if (parsed) {
            this.date = Math.trunc(parsed / 1000);
        } else {
            throw new Error("Invalid props value, correct the 'end'");
        }

        interval = setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
            this.laodingPeriod = false;
        }, 1000);
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60;
        },

        minutes() {
            return Math.trunc(this.diff / 60) % 60;
        },

        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24;
        },

        days() {
            return Math.trunc(this.diff / 60 / 60 / 24);
        },
    },
    watch: {
        now() {
            this.diff = this.date - this.now;
            if (this.diff <= 0 || this.stop) {
                this.diff = 0;
                this.endFunction();
                // Remove interval
                clearInterval(interval);
            }
        },
        hours() {
            if (this.hours <= 24 && !this.days) {
                this.$emit('hours', true);
            }
        },
    },
    filters: {
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString();
            }
            return value.toString();
        },
    },
    destroyed() {
        clearInterval(interval);
    },
};
</script>
<style lang="scss" scoped>
.count {
    color: #fff;
    border-radius: 0.2rem;
    width: 39px;
    // height: -webkit-fill-available;
    height: full;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(138deg, #3ccd1e 0%, #049c23 100%) 0%
        0% no-repeat padding-box;
    opacity: 1;
}
</style>
