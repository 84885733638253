<template>
    <div>
        <div class="position-relative" v-if="activePromotion">
            <img
                src="@/assets/img/category_list/category_list_page.png"
                alt="header image"
                class="w-100 d-none d-lg-block"
            />
            <h1 class="title-center">
                {{ activePromotion.title }}
            </h1>
            <div class="countdown-center">
                <!-- <count-down
                    :end="
                        activePromotion.end_date
                            .split(' ')[0]
                            .concat('T', activePromotion.end_date.split(' ')[1])
                    "
                    showDays
                    showHours
                    showMinutes
                    showSeconds
                    countdownText="Ending In"
                ></count-down> -->
                <count-down
                    :end="timerProp.timer"
                    showDays
                    showHours
                    showMinutes
                    showSeconds
                    :countdownText="timerProp.title"
                    @hours="hours"
                ></count-down>
            </div>
        </div>
        <div class="container py-5">
            <div
                v-if="windowWidth >= 992"
                class="d-flex justify-content-between align-items-center gap-4"
            >
                <div class="d-flex align-items-center gap-4">
                    <vue-horizontal
                        v-if="categories && categories.length > 0"
                        responsive
                        class="sub-list-style"
                        :displacement="0.5"
                        snap="center"
                    >
                        <!-- <ul
                        class="sub-list-style row flex-nowrap overflow-auto"
                        v-if="categories && categories.length > 0"
                    > -->
                        <div
                            style="width: max-content;font-weight: bold;"
                            class="list-unstyled sub-title"
                            :class="[activeFilter('')]"
                            @click="() => (category_filter = '')"
                        >
                            All Jewellery
                        </div>
                        <div
                            style="none; width: max-content;font-weight: bold;"
                            class="list-unstyled sub-title"
                            v-for="(category, index) in categories"
                            :key="index"
                        >
                            <span
                                v-if="category_filter == category.id"
                                :class="[activeFilter(category.id)]"
                                >{{ category.name }}</span
                            >
                            <span
                                v-else
                                @click="() => (category_filter = category.id)"
                                >{{ category.name }}</span
                            >
                        </div>
                    </vue-horizontal>
                    <!-- </ul> -->
                    <ul class="sub-list-style" v-else>
                        <li>
                            <span>No sub-categories found!</span>
                        </li>
                    </ul>
                    <!-- <div>
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div> -->
                </div>
                <div
                    class="d-flex gap-2 mt-xs-2 justify-content-center justify-content-lg-start"
                >
                    <div
                        @click="toWishlist"
                        style="border:1px solid black;border-radius:5px;padding:0.3rem;cursor:pointer"
                    >
                        <heart-icon /> Wishlist
                    </div>
                    <div
                        @click="showWebFilter = true"
                        style="border:1px solid black;border-radius:5px;padding:0.3rem;cursor:pointer"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'sort-amount-down']"
                        />
                        Filters
                    </div>
                </div>
            </div>
            <div
                v-else
                class="d-flex justify-space-between align-items-center gap-2"
            >
                <div
                    class="aselect flex-grow-1"
                    :data-value="selectedSubCat"
                    :data-list="menuLinks"
                >
                    <div class="selector" @click="toggle()">
                        <div class="label">
                            <span>{{ selectedSubCat }}</span>
                        </div>
                        <div
                            class="arrow"
                            :class="{ expanded: dropdownVisible }"
                        ></div>
                        <div
                            :class="{
                                hidden: !dropdownVisible,
                                dropdownVisible,
                            }"
                        >
                            <ul>
                                <li
                                    class="d-flex align-items-center me-2"
                                    :class="{
                                        current:
                                            category.name === selectedSubCat,
                                    }"
                                    v-for="(category, index) in menuLinks"
                                    :key="index"
                                    @click="select(category)"
                                >
                                    <input
                                        type="radio"
                                        style="accent-color: green;margin-right: 0.5rem"
                                        :checked="
                                            category.name === selectedSubCat
                                        "
                                    />
                                    {{ category.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="d-flex gap-2">
                    <div
                        class="btn-sm-outline"
                        style="border:1px solid gainsboro;border-radius:5px;padding:0.3rem;cursor:pointer"
                        @click="toWishlist"
                    >
                        <heart-icon />
                    </div>
                    <div
                        class="btn-sm-outline"
                        style="border:1px solid gainsboro;border-radius:5px;padding:0.3rem;cursor:pointer"
                        @click="showWebFilter = true"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'sort-amount-down']"
                        />
                    </div>
                </div>
            </div>
            <div id="filterBox">
                <transition
                    @enter="startTransitionModal"
                    @after-enter="endTransitionModal"
                    @before-leave="endTransitionModal"
                    @after-leave="startTransitionModal"
                >
                    <div
                        class="modal fade"
                        style="background: #0000001A;"
                        v-show="showWebFilter"
                        ref="modal"
                        @click.self="showWebFilter = false"
                    >
                        <div
                            class="modal-dialog modal-sm modal-dialog-centered"
                        >
                            <div class="modal-content">
                                <div class="modal-header border-0 mt-2 p-1">
                                    <!-- <a
                                            href="javascript:void(0)"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            @click="showWebFilter = false"
                                        ></a> -->
                                </div>
                                <div class="modal-body">
                                    <div
                                        class="d-flex flex-column align-items-start justify-content-start"
                                    >
                                        <div class="filterType">
                                            <h6 class="fw-bold">
                                                Gold Type
                                            </h6>
                                            <ul class="list-unstyled">
                                                <li
                                                    class="filterType__item ms-2"
                                                    v-for="(gold_type,
                                                    index) in goldTypes"
                                                    :key="index"
                                                >
                                                    <input
                                                        :id="
                                                            `filterGoldType${index}`
                                                        "
                                                        class="form-check-input me-2"
                                                        type="checkbox"
                                                        name="filterPrice"
                                                        v-model="
                                                            gold_type_filter
                                                        "
                                                        :value="gold_type.id"
                                                    />
                                                    <label
                                                        :for="
                                                            `filterGoldType${index}`
                                                        "
                                                    >
                                                        {{ gold_type.name }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- pricing_filter -->
                                        <div
                                            class="filterType"
                                            v-if="
                                                pricingGroups &&
                                                    pricingGroups.length > 0
                                            "
                                        >
                                            <h6 class="fw-bold">
                                                Price Range
                                            </h6>
                                            <ul class="list-unstyled">
                                                <li class="filterType__item">
                                                    <input
                                                        id="
                                                                `filterPriceMenu-0`
                                                            "
                                                        class="form-check-input me-2"
                                                        type="radio"
                                                        name="filterPrice"
                                                        v-model="pricing_filter"
                                                        value=""
                                                    />
                                                    <label
                                                        for="
                                                                `filterPriceMenu$-0`
                                                            "
                                                    >
                                                        Default
                                                    </label>
                                                </li>
                                                <li
                                                    class="filterType__item"
                                                    v-for="(pc,
                                                    index) in pricingGroups"
                                                    :key="index"
                                                >
                                                    <input
                                                        :id="
                                                            `filterPriceMenu${pc.id}`
                                                        "
                                                        class="form-check-input me-2"
                                                        type="radio"
                                                        name="filterPrice"
                                                        v-model="pricing_filter"
                                                        :value="pc.value"
                                                    />
                                                    <label
                                                        :for="
                                                            `filterPriceMenu${pc.id}`
                                                        "
                                                    >
                                                        {{ pc.label }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-between py-2 border-top"
                                    >
                                        <button
                                            style="background:#fff; border:none"
                                            href="javascript:void(0)"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            @click="filteringProducts(false)"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            @click="filteringProducts(true)"
                                            style="background: #F6F6F6 0% 0% no-repeat padding-box;border-radius: 30px;opacity: 1;border:none;padding:0.2rem 1rem;font-weight:bold"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="modal-backdrop fade d-none" ref="backdrop"></div>
            </div>
            <div
                class="d-flex flex-wrap justify-content-center justify-content-md-start mt-4 pb-4 pt-2"
                style="gap:1rem"
            >
                <div
                    class="card"
                    v-for="(product, index) in validProducts"
                    :key="index"
                >
                    <div>
                        <img
                            :src="product.cover_photo"
                            :alt="product.title"
                            class="img-fluid cursor"
                            @click="toDetailPage(product.slug)"
                        />
                        <font-awesome-icon
                            v-show="isLoggedIn"
                            @click="toggleWish(product.id)"
                            :icon="['fas', 'heart']"
                            class="icon position-absolute rounded-circle bg-light"
                            :class="{
                                'wish-list': hasInWishList(product.id),
                            }"
                            :id="`active-wish-${product.id}`"
                        />
                        <div
                            class="my-2 mx-2"
                            @click="toDetailPage(product.slug)"
                        >
                            <p class="arrival-body" style="font-size: 14px;">
                                {{ product.title }}
                            </p>
                            <div class="d-flex flex-column flex-lg-row gap-1">
                                <!-- <p
                                    v-if="
                                        product.productvariants[0]
                                            .discounted_price
                                    "
                                    class="arrival-body fw-bold"
                                >
                                    {{
                                        addCommaForPrice(
                                            product.productvariants[0]
                                                .discounted_price,
                                        )
                                    }}
                                    MMK
                                </p> -->
                                <p
                                    v-if="discountedVariant(product)"
                                    class="arrival-body fw-bold"
                                >
                                    {{
                                        addCommaForPrice(
                                            discountedVariant(product)
                                                .discounted_price,
                                        )
                                    }}
                                    MMK
                                </p>
                                <p
                                    class="sold-out text-center"
                                    v-if="
                                        soldOutedProducts.includes(product.id)
                                    "
                                >
                                    Out of Stock
                                </p>
                            </div>
                            <div class="d-flex flex-column flex-lg-row gap-1">
                                <!-- <p
                                    class="arrival-body"
                                    :class="
                                        product.productvariants[0]
                                            .discounted_price
                                            ? 'text-decoration-line-through'
                                            : 'fw-bold fs-6'
                                    "
                                    style="font-size: 14px;"
                                >
                                    {{
                                        addCommaForPrice(
                                            product.productvariants[0]
                                                .final_price,
                                        )
                                    }}
                                    MMK
                                </p> -->
                                <p
                                    class="arrival-body"
                                    :class="
                                        discountedVariant(product)
                                            ? 'text-decoration-line-through'
                                            : 'fw-bold fs-6'
                                    "
                                    style="font-size: 14px;"
                                >
                                    {{
                                        addCommaForPrice(
                                            originalVariantPrice(product),
                                        )
                                    }}MMK
                                </p>
                                <!-- <p
                                    class="arrival-body"
                                    style="font-size: 14px;"
                                    v-if="
                                        product.productvariants[0]
                                            .discounted_type != 0
                                    "
                                >
                                    {{
                                        product.productvariants[0]
                                            .discounted_amount
                                    }}
                                    {{
                                        product.productvariants[0]
                                            .discounted_type == 1
                                            ? '%'
                                            : 'MMK'
                                    }}
                                    OFF
                                </p> -->
                                <p
                                    class="arrival-body"
                                    style="font-size: 14px;"
                                    v-if="discountedVariant(product)"
                                >
                                    {{
                                        discountedVariant(product)
                                            .discounted_amount
                                    }}
                                    {{
                                        discountedVariant(product)
                                            .discounted_type == 1
                                            ? '% OFF'
                                            : 'MMK OFF'
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!products" class="text-center">
                <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <!-- show more button -->
            <div class="text-center mb-5" v-if="products">
                <p>{{ current_count }}/{{ total_count }}</p>
                <button v-if="hasMorePages" class="btn" @click="showMore">
                    Show More
                </button>
            </div>
        </div>
        <!-- modal box -->
        <div
            v-if="notifyModal"
            class="modal notifyModal"
            tabindex="-1"
            aria-labelledby="notifyModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div
                        class="modal-body d-flex flex-column justify-content-center align-items-center"
                    >
                        <h3 class="mx-auto">
                            Promotion is not starting yet
                        </h3>
                        <span @click="notifyModal = false" class="btn"
                            >Close</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Button from '../components/reusable/Button.vue';
import Items from '@/mixins/Items';
import Countdown from '../components/reusable/Countdown.vue';

import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

// apollo query
// import AllCategories from '@/graphql/queries/product/Categories.gql';
// import Categories from '@/graphql/queries/home/Categories.gql';
import Products from '@/graphql/queries/product/Products.gql';
import PromotionCategories from '@/graphql/queries/promotion/PromotionCategories.gql';
// import PriceRage from '@/graphql/queries/product/PriceRange.gql';
import PricingGroup from '@/graphql/queries/product/PricingGroup.gql';
import activePromotion from '@/graphql/queries/home/ActivePromotion.gql';

export default {
    mixins: [Items, CommaPrice, AddToWishList, encryptDecrypt],

    data() {
        return {
            filtered_count: 0,
            current_page: 1,
            hasMorePages: false,
            current_count: 0,
            total_count: 0,
            showWebFilter: false,
            soldOutedProducts: [],
            pricingGroups: null,
            category_filter: '',
            pricing_filter: '',
            gold_type_filter: [],
            windowWidth: window.innerWidth,
            selectedSubCat: 'All jewellery',
            dropdownVisible: false,
            categories: [],
            notifyModal: false,
        };
    },

    components: {
        'count-down': Countdown,
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.getActivePromotion();
        // this.getProducts();
        // this.getPrices();
        // this.getCategories();
        this.getPricingGroups();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    computed: {
        ...mapGetters({
            activePromotion: 'promotion/getActivePromotion',
            products: 'product/getProducts',
            isLoggedIn: 'auth/isLoggedIn',
            goldTypes: 'home/getAllGoldTypes',
        }),
        validProducts() {
            return this.products?.data;
        },
        productVariables() {
            let variable = {};
            if (this.$route.query.category_id) {
                variable.category_id = this.decryptAES(
                    this.$route.query.category_id,
                    'pss',
                );
            }
            if (this.category_filter) {
                variable.category_id = this.category_filter;
            }
            if (this.pricing_filter) {
                variable.min_price = this.pricing_filter.min_price;
                variable.max_price = this.pricing_filter.max_price;
            }
            if (this.gold_type_filter.length) {
                variable.gold_type_ids = this.gold_type_filter;
            }
            return variable;
        },
        // validProducts() {
        //     let preparedProducts = this.products.data
        //         .map(element => {
        //             return {
        //                 ...element,
        //                 productvariants: element.productvariants.filter(pv => {
        //                     return (
        //                         pv.discount_type !== 0 && pv.total_in_stock > 0
        //                     );
        //                 }),
        //             };
        //         })
        //         .filter(prd => prd.productvariants.length > 0);
        //     return preparedProducts;
        // },
        menuLinks() {
            return this.categories;
        },
        timerProp() {
            if (new Date(this.activePromotion?.start_date) > new Date()) {
                return {
                    is_upcoming: true,
                    title: 'Starting In',
                    timer: this.activePromotion?.start_date
                        ?.split(' ')[0]
                        .concat(
                            'T',
                            this.activePromotion?.start_date?.split(' ')[1],
                        ),
                };
            } else {
                return {
                    is_upcoming: false,
                    title: 'Ending In',
                    timer: this.activePromotion?.end_date
                        ?.split(' ')[0]
                        .concat(
                            'T',
                            this.activePromotion.end_date?.split(' ')[1],
                        ),
                };
            }
        },
    },
    watch: {
        category_filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getProducts();
            }
        },
    },
    methods: {
        originalVariantPrice(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                )?.final_price ??
                prd.productvariants.find(pv => pv.total_in_stock > 0)
                    ?.final_price ??
                prd.productvariants[0]?.final_price
            );
        },
        discountedVariant(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                ) || null
            );
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        getCategories() {
            this.$apollo
                .query({
                    query: PromotionCategories,
                    variables: {
                        promotion_id: this.activePromotion.id,
                    },
                })
                .then(response => {
                    console.log(response.data.PromotionCategories);
                    this.categories = response.data.PromotionCategories;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filteringProducts(isFiltering) {
            if (!isFiltering) {
                this.category_filter = '';
                this.gold_type_filter = [];
                this.pricing_filter = '';
            }
            this.getProducts();
            this.showWebFilter = false;
        },
        formatDay() {
            return this.activePromotion.end_date.split('-')[2];
        },
        toggle() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        select(option) {
            this.selectedSubCat = option.name;
            this.category_filter = option.id;
        },
        formatMonth() {
            const date = new Date(this.activePromotion.end_date.split('-')[1]);
            const shortMonthName = new Intl.DateTimeFormat('en-US', {
                month: 'short',
            }).format;
            const shortName = shortMonthName(date); // "Jul"
            return shortName;
        },
        formatYear() {
            return this.activePromotion.end_date.split('-')[0];
        },
        getPricingGroups() {
            this.$apollo
                .query({
                    query: PricingGroup,
                })
                .then(response => {
                    this.pricingGroups = response.data.pricinggroup;
                    this.pricingGroups.map(gp => {
                        gp.value = {
                            min_price: gp.min_price,
                            max_price: gp.max_price,
                        };
                        switch (gp.id) {
                            case 1:
                                gp.label = `Below ${
                                    String(gp.max_price)[0]
                                }Lakh`;
                                break;
                            case 2:
                                gp.label = `Between ${
                                    String(gp.min_price)[0]
                                }Lakh-${String(gp.max_price).slice(0, 2)}Lakh`;
                                break;
                            case 3:
                                gp.label = `Between ${String(
                                    gp.min_price,
                                ).slice(0, 2)}Lakh-${String(gp.max_price).slice(
                                    0,
                                    3,
                                )}Lakh`;
                                break;
                            case 4:
                                gp.label = `Upper ${String(gp.min_price).slice(
                                    0,
                                    3,
                                )}Lakh`;
                                break;
                        }
                    });
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        toWishlist() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'WishList',
                });
            } else {
                this.$router.push({
                    name: 'Login',
                });
            }
        },
        activeFilter(item) {
            return this.category_filter == item ? 'active-category' : '';
        },
        // getPrices() {
        //     this.$apollo
        //         .query({
        //             query: PriceRage,
        //         })
        //         .then(response => {
        //             this.min_price = response.data.productpricing.min_price;
        //             this.max_price = response.data.productpricing.max_price;

        //             this.default_min_price = this.min_price;
        //             this.default_max_price = this.max_price;

        //             this.getProducts();
        //         })
        //         .catch(error => {
        //             console.log(error.response);
        //         });
        // },
        getProducts() {
            this.$apollo
                .query({
                    query: Products,
                    fetchPolicy: 'no-cache',
                    variables: {
                        ...this.productVariables,
                        promotion_id: this.activePromotion?.id,
                    },
                })
                .then(response => {
                    this.total_count =
                        response.data.filterProducts.paginatorInfo.total;
                    this.filtered_count = this.total_count;
                    this.current_count =
                        response.data.filterProducts.paginatorInfo.count;
                    this.hasMorePages =
                        response.data.filterProducts.paginatorInfo.hasMorePages;
                    this.current_page = 1;

                    this.$store.commit(
                        'product/ADD_PRODUCTS',
                        response.data.filterProducts,
                    );

                    const products = response.data.filterProducts.data;
                    products.map(product => {
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock <= 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        toDetailPage(slug) {
            if (this.timerProp.is_upcoming) {
                this.notifyModal = true;
            } else {
                this.$router.push({
                    // name: 'ProductDetail',
                    name: 'ProductVariantDetail',
                    params: {
                        slug: slug,
                    },
                });
            }
        },
        showMore() {
            this.current_page++;
            this.$apollo
                .query({
                    query: Products,
                    variables: {
                        ...this.productVariables,
                        promotion_id: this.activePromotion.id,
                        page: this.current_page,
                    },
                })
                .then(response => {
                    this.current_count =
                        this.current_count +
                        response.data.filterProducts.paginatorInfo.count;
                    this.hasMorePages =
                        response.data.filterProducts.paginatorInfo.hasMorePages;

                    const products = response.data.filterProducts.data;

                    products.map(product => {
                        this.$store.commit('product/APPEND_PRODUCTS', product);
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock <= 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error.response);
                });
        },

        async getActivePromotion() {
            try {
                const promotions = await this.$apollo.query({
                    query: activePromotion,
                    fetchPolicy: 'no-cache',

                    variables: {
                        first: 1,
                        page: this.current_page,
                    },
                });

                if (promotions) {
                    this.$store.commit(
                        'promotion/ADD_ACTIVE_PROMOTION',
                        promotions.data.activePromotion,
                    );
                    this.getCategories();
                    this.getProducts();
                }
            } catch (error) {
                console.log(error);
            }
        },
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/button.scss';
@import '@/sass/components/checkbox.scss';
.form-check-input {
    vertical-align: 0;
}
.form-check-input:checked[type='radio'] {
    background-image: none;
}
.sub-list-style {
    margin: 0;
    cursor: pointer;
    padding: 0;
    font-size: 30px;
    letter-spacing: -0.58px;
    color: #111111;
    max-width: 67vw;
}
.card {
    width: 10rem;
    // margin: 0 1rem 1rem 0rem;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    .icon {
        cursor: pointer;
        color: rgb(220, 217, 217);
        padding: 6px;
        width: 1.8rem;
        height: 1.8rem;
        top: 10px;
        right: 0px;
    }
    .wish-list {
        color: #77c306;
    }
    // img {
    //     height: 12rem;
    // }
    .arrival-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .sold-out {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 50px;
        background: transparent
            linear-gradient(102deg, #e43225 0%, #ff5400 100%) 0% 0% no-repeat
            padding-box;
    }
}
.active-category {
    position: relative;
    transition: color 1s;
    color: #77c306;
    &:after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 2.375rem;
        padding-top: 0px;
        border-bottom: 0.3rem solid #77c306;
    }
}
.webFilterBox {
    display: block;
    transition: opacity 0.15s linear;
    background: #0000006e;

    @media (max-width: 767.98px) {
        display: none;
    }
}
.title-center,
.countdown-center {
    font-size: calc(100% + 1vw + 1vh);
    color: #333;
    text-align: center;
    margin-top: 1rem;
}
@media (min-width: 576px) {
    .card {
        width: 15rem;
        img {
            height: 15rem;
        }
    }
}
@media (min-width: 992px) {
    .title-center {
        color: #fff;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .countdown-center {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 40%;
    }
}
.filterType {
    max-height: 143px;
    overflow-y: scroll;
    margin-bottom: 20px;
}
.sub-title {
    font-size: 1.3rem;
}
@media (min-width: 768px) {
    .sub-title {
        font-size: 1.6rem;
    }
}
.aselect {
    // width: 280px;
    margin: 20px auto;
    .selector {
        border: 1px solid gainsboro;
        // background: #f8f8f8;
        position: relative;
        border-radius: 8px;
        z-index: 1;
        .arrow {
            position: absolute;
            right: 10px;
            top: 40%;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid #888;
            transform: rotateZ(0deg) translateY(0px);
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
        }
        .expanded {
            transform: rotateZ(180deg) translateY(2px);
        }
        .label {
            display: block;
            padding: 12px;
            font-size: 16px;
            color: #888;
        }
    }
    ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        border: 1px solid gainsboro;
        position: absolute;
        z-index: 1;
        background: #fff;
    }
    li {
        padding: 12px;
        color: #666;
        input[type='radio'] {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            outline: none;
            border: 3px solid gray;
        }

        input[type='radio']:before {
            content: '';
            display: block;
            width: 60%;
            height: 60%;
            margin: 20% auto;
            border-radius: 50%;
        }

        input[type='radio']:checked:before {
            background: green;
        }
        input[type='radio']:checked {
            border-color: green;
        }

        &:hover {
            // color: white;
            // background: seagreen;
            background: #f8f8f8;
        }
    }
    .current {
        background: #eaeaea;
    }
    .hidden {
        visibility: hidden;
    }
    .visible {
        visibility: visible;
    }
}
.notifyModal {
    display: block;
    background: #00000085;
    a {
        color: #000;
    }
}
</style>
